define("workflows-web/components/react-components/trigger-data-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hP30//RJ",
    "block": "{\"symbols\":[\"@isTriggerSelected\",\"@isWorkflowActive\",\"@isTriggerDataloading\",\"@triggerListData\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,2],[\"trigger-data-container\",[30,[36,1],[[30,[36,0],[[32,1]],null],\"hf-workflows_trigger-data-wrapper\"],null]],null]],[4,[38,3],null,[[\"component\",\"triggerListData\",\"isTriggerDataloading\",\"isTriggerSelected\",\"isWorkflowActive\"],[[32,0,[\"ReactComponent\"]],[32,4],[32,3],[32,1],[32,2]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"concat-strings\",\"react-render\"]}",
    "meta": {
      "moduleName": "workflows-web/components/react-components/trigger-data-wrapper/template.hbs"
    }
  });
});