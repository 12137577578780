define("workflows-web/workflows/mixins/happyfox/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    getHappyfoxTriggers: function getHappyfoxTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var happyfoxTriggerChoices = Ember.A();
      var ticketCreated = triggerClass.create({
        stepType: 'ticket_created',
        eventType: 'trigger',
        application: 'happyfox',
        labelInformation: 'happyfox message',
        color: '#102aac',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var ticketUpdated = triggerClass.create({
        stepType: 'ticket_updated',
        eventType: 'trigger',
        application: 'happyfox',
        color: '#570fae',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var agentInitiatedTrigger = triggerClass.create({
        stepType: 'agent_initiated_trigger',
        eventType: 'trigger',
        application: 'happyfox',
        color: '#570fae',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var smartRuleTrigger = triggerClass.create({
        stepType: 'smart_rule_trigger',
        eventType: 'trigger',
        application: 'happyfox',
        color: '#570fae',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      happyfoxTriggerChoices.addObjects([ticketCreated, ticketUpdated, agentInitiatedTrigger, smartRuleTrigger]);
      happyfoxTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return happyfoxTriggerChoices;
    }
  });
});